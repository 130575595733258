import { useTweakwiseNavigation } from '@emico-hooks/tweakwise-navigation'
import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import { ButtonUnstyled } from '@emico-react/buttons'
import { pushViewItemList } from '@emico-utils/datalayer'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import { globalWindow } from '@emico-utils/ssr-utils'
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-collapse'

import { Maybe } from '@emico/graphql-schema-types'
import { maxWidth, minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'
import { sortArrayByProperty } from '@emico/utils'

import FilterIcon from '../icons/FilterIcon'
import { FilterExplanationList } from '../lib/craftCategoryExplanationListsFragment'
import {
  CraftCategoryBannersFragment,
  CraftCategoryFragment,
  CraftCategoryLandingFragment,
} from '../lib/craftFragments.generated'
import { CategoryItem, TweakwiseNavigationResult } from '../lib/customTypes'
import getLandingPageUrlsVariables from '../lib/getLandingPageUrlsVariables'
import getSeoPageTitle from '../lib/getSeoPageTitle'
import getTrackingProducts from '../lib/getTrackingProducts'
import getTweakwiseNavigationVariables from '../lib/getTweakwiseNavigationVariables'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import { useLandingPageUrls } from '../lib/useLandingPageUrls'
import { CategoryQuery } from '../pages/category/useCategory.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import ButtonSecondary from './ButtonSecondary'
import CategoryActiveFilters from './CategoryActiveFilters'
import CategoryFiltersForm from './CategoryFiltersForm'
import CategoryFiltersFormModal from './CategoryFiltersFormModal'
import CategoryPagination from './CategoryPagination'
import CategoryProductList from './CategoryProductList'
import CategorySortForm from './CategorySortForm'
import Container from './Container'
import HtmlContent from './HtmlContent'
import Meta from './Meta'
import PageHeader from './PageHeader'
import PreviouslyViewedProducts from './PreviouslyViewedProducts'
import QuickFilters from './QuickFilters'
import { StyledBaseSelect } from './Select'

const Section = styled.section`
  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const FilterButtonsMobileAndTablet = styled.div`
  position: sticky;
  top: ${theme.sizes.headerHeight};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing.sm};
  margin-bottom: ${theme.spacing.md};
  padding: ${theme.spacing.sm} 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: ${theme.zIndices.sticky};

  ${StyledBaseSelect} {
    border: ${theme.borders.md} ${theme.colors.borderDark};
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledButtonSecondary = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${maxWidth('md')} {
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.bold};
  }
`

const StyledFilterIcon = styled(FilterIcon)`
  font-size: 15px;
  margin-right: ${theme.spacing.sm};
`

const CategoryFiltersFormModalMobileAndTabletWrapper = styled.div`
  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledContainer = styled(Container)`
  @media ${minWidth('lg')} {
    padding: 0 ${theme.spacing['2xl']};
  }
`

const Grid = styled.div`
  display: grid;
  margin-top: ${theme.spacing.xl};

  @media ${minWidth('lg')} {
    grid-template-columns: 1fr 3fr;
    gap: ${theme.spacing['2xl']};
    margin-top: ${theme.spacing['2xl']};
  }
`

const AsideDesktop = styled.aside`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const TopText = styled.span`
  padding-bottom: ${theme.spacing.sm};
  color: ${theme.colors.grayBrown};
  font-size: ${theme.fontSizes.sm};
  border-bottom: ${theme.borders.default};
  display: block;
`

const Header = styled.header`
  margin-bottom: ${theme.spacing.xxs};
`

const IntroductionReadMoreWrapper = styled('div', {
  shouldForwardProp: (prop) => !['showAll'].includes(prop.toString()),
})<{ showAll: boolean }>`
  display: ${({ showAll }) => !showAll && 'flex'};
  align-items: ${({ showAll }) => !showAll && 'flex-start'};
  margin-bottom: ${theme.spacing.md};

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.xl};
  }
`

const Introduction = styled('div', {
  shouldForwardProp: (prop) => !['showAll'].includes(prop.toString()),
})<{ showAll: boolean }>`
  ${({ showAll }) => !showAll && theme.stylingSnippet.lineClamp(1)}
`

const IntroductionReadMoreButton = styled(ButtonUnstyled)`
  flex-shrink: 0;
  color: ${theme.colors.grayBrown};
  margin-left: ${theme.spacing.xs};
`

const IntroductionReadLessButton = styled(ButtonUnstyled)`
  margin-top: ${theme.spacing.xs};
  color: ${theme.colors.grayBrown};
`

const SortByWrapperDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${StyledBaseSelect} {
    border-color: ${theme.colors.border};
  }

  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledCategorySortForm = styled(CategorySortForm)`
  margin: 0 0 ${theme.spacing.lg} auto;
`

const StyledCategoryPagination = styled(CategoryPagination)`
  margin-top: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing['2xl']};
  }
`

const StyledPreviouslyViewedProducts = styled(PreviouslyViewedProducts)`
  margin-top: ${theme.spacing['2xl']};

  @media ${minWidth('lg')} {
    margin-top: ${theme.spacing['3xl']};
  }
`

const SeoTextWrapper = styled.div`
  margin-top: ${theme.spacing.xl};

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing['2xl']};
  }

  @media ${minWidth('lg')} {
    margin-top: ${theme.spacing['3xl']};
  }
`

const SeoIntroductionTitle = styled(H2)`
  margin: ${theme.spacing.xl} 0 ${theme.spacing.sm};

  @media ${minWidth('md')} {
    margin-top: ${theme.spacing['2xl']};
  }

  @media ${minWidth('lg')} {
    margin-top: ${theme.spacing['3xl']};
  }
`

const SeoIntroductionTextWrapper = styled.div`
  padding-bottom: ${theme.spacing.md};
  border-bottom: ${theme.borders.default};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.md} 0;
  }
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.md};
`

const CollapsibleItemLabel = styled.span`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  padding-right: ${theme.spacing.sm};
  text-transform: uppercase;
`

const handleBannerPositions = (
  banners: CraftCategoryBannersFragment[],
  items: CategoryItem[],
) => {
  const sortedPromotionBanners = sortArrayByProperty(banners, (banner) =>
    banner?.position || banner?.position === 0 ? banner.position : null,
  )

  sortedPromotionBanners.forEach((banner, index) =>
    items.splice(banner.position, 0, banner),
  )
}

interface Props extends DefaultLayoutStaticData {
  disableEcommerce: boolean
  categoryData: Exclude<CategoryQuery['categoryByUid'], null>
  rootCategoryTweakwiseItemNumber: Maybe<string>
  tweakwiseItemNumber: Maybe<string>
  tweakwiseData: TweakwiseNavigationResult
  craftDataCategory?: CraftCategoryFragment
  craftDataCategoryLanding?: CraftCategoryLandingFragment
  craftDataFilterExplanations?: FilterExplanationList[]
  initialLandingPageFacetParam?: string
}

const CategoryLayout = ({
  websiteData,
  disableEcommerce,
  categoryData,
  rootCategoryTweakwiseItemNumber,
  tweakwiseItemNumber,
  tweakwiseData,
  craftDataCategory,
  craftDataCategoryLanding,
  craftDataFilterExplanations,
  initialLandingPageFacetParam,
}: Props) => {
  const activeStoreView = useActiveStoreView()
  const router = useRouter()
  const productGridRef = useRef<HTMLDivElement>(null)
  const { query } = router
  const facetParam = query.facets as string
  const sortParam = query.sort as string
  const pageParam = query.page as string
  const searchParam = query.query as string

  const headerData =
    craftDataCategoryLanding?.title && craftDataCategoryLanding.image?.[0]
      ? {
          title: craftDataCategoryLanding.title,
          subtitle: craftDataCategoryLanding.subtitle ?? undefined,
          image: craftDataCategoryLanding.image[0],
        }
      : {
          title: craftDataCategory?.title,
          subtitle: craftDataCategory?.subtitle ?? undefined,
          image: craftDataCategory?.image?.[0],
        }

  const [isFacetMenuActive, setIsFacetMenuActive] = useState<boolean>(false)

  const [isPushedViewItemList, setIsPushedViewItemList] =
    useState<boolean>(false)

  const [showCategoryFiltersModal, setShowCategoryFiltersModal] =
    useState<boolean>(false)

  const [showFullIntroduction, setShowFullIntroduction] =
    useState<boolean>(false)

  const categoryUid = categoryData.uid
  const categoryTitleFallback = categoryData.name
  const tweakwiseNavigationVariables = searchParam
    ? {
        query: searchParam,
        ...getTweakwiseNavigationVariables(
          rootCategoryTweakwiseItemNumber ?? '',
          pageParam,
          facetParam,
          sortParam,
        ),
      }
    : {
        ...getTweakwiseNavigationVariables(
          tweakwiseItemNumber ?? '',
          pageParam,
          facetParam,
          sortParam,
          initialLandingPageFacetParam,
        ),
      }

  const {
    data: tweakwiseDataNavigationDynamic,
    loading: isLoadingTweakwiseNavigation,
  } = useTweakwiseNavigation(tweakwiseNavigationVariables, {
    errorPolicy: 'all',
  })

  const tweakwiseNavigationResults =
    tweakwiseDataNavigationDynamic ?? tweakwiseData
  const facets = tweakwiseNavigationResults.facets ?? []
  const sortOptions = tweakwiseNavigationResults.properties.sortFields ?? []
  const resultCount = tweakwiseNavigationResults.properties.nrOfItems

  const hasActiveFacetFilters = facets?.some((facet) =>
    facet.attributes.find((attribute) => attribute.isSelected),
  )

  const tweakwiseLandingPageVariables = getLandingPageUrlsVariables(
    categoryUid,
    searchParam ? rootCategoryTweakwiseItemNumber : tweakwiseItemNumber,
    facets,
    facetParam,
    router.asPath,
    initialLandingPageFacetParam,
  )

  const { data: landingPageUrlsData, loading: isLoadingLandingPageUrls } =
    useLandingPageUrls(tweakwiseLandingPageVariables)

  const filterExplanationData = craftDataFilterExplanations?.find(
    (explanationList) =>
      explanationList?.__typename === 'CraftFilterExplanationListEntry' &&
      String(explanationList.tweakwiseCategoryId) ===
        categoryData.tweakwiseItemNumber,
  )

  const filterExplanations =
    filterExplanationData?.__typename === 'CraftFilterExplanationListEntry'
      ? filterExplanationData
      : undefined

  const products = tweakwiseNavigationResults.items
    .map((item) => item.product)
    .filter(stripMaybes)
  const items: CategoryItem[] = [...products]

  const promotionBanners =
    craftDataCategory?.categoryBanners.filter(stripMaybes) ?? []

  const activeBanners = hasActiveFacetFilters
    ? promotionBanners.filter((banner) => banner.isVisibleOnFilter)
    : promotionBanners

  let pageTitle = craftDataCategoryLanding?.pageTitle
    ? craftDataCategoryLanding.pageTitle
    : craftDataCategory?.pageTitle

  if (searchParam) {
    pageTitle = `${t({
      message: 'Search results for:',
    })} ${searchParam}`
  }

  const craftMetaTitle =
    craftDataCategoryLanding?.metaTitle ?? craftDataCategory?.metaTitle

  const craftMetaDescription =
    craftDataCategoryLanding?.metaDescription ??
    craftDataCategory?.metaDescription

  const metaTitle = searchParam
    ? t({ message: `Search - ${pageTitle}` })
    : craftMetaTitle ??
      categoryData.metaTitle ??
      getSeoPageTitle(craftDataCategory?.title ?? categoryData.name)

  const metaDescription = searchParam
    ? metaTitle
    : craftMetaDescription ?? categoryData.metaDescription ?? categoryData.name

  const craftSeoIntroductionTitle =
    craftDataCategoryLanding?.seoIntroductionTitle ??
    craftDataCategory?.seoIntroductionTitle

  const craftSeoIntroductionText =
    craftDataCategoryLanding?.seoIntroductionRichText ??
    craftDataCategory?.seoIntroductionRichText

  const craftSeoCollapsibleRichTexts =
    craftDataCategoryLanding?.collapsibleRichTexts &&
    craftDataCategoryLanding.collapsibleRichTexts.length !== 0
      ? craftDataCategoryLanding?.collapsibleRichTexts
      : craftDataCategory?.collapsibleRichTexts

  handleBannerPositions(activeBanners, items)

  const hasQuickFiltersLanding =
    craftDataCategoryLanding &&
    craftDataCategoryLanding.quickFilters.length !== 0

  const hasQuickFiltersCategory =
    craftDataCategory && craftDataCategory.quickFilters.length !== 0

  const quickFilters = hasQuickFiltersLanding
    ? craftDataCategoryLanding?.quickFilters
    : hasQuickFiltersCategory
    ? craftDataCategory?.quickFilters
    : []

  useEffect(() => {
    if (isPushedViewItemList || products?.length === 0) {
      return
    }

    setIsPushedViewItemList(true)

    const productList = getTrackingProducts(
      products,
      activeStoreView.code,
      true,
    ).slice(0, 18)

    pushViewItemList(
      productList,
      'filter_results',
      pageTitle ?? categoryTitleFallback,
    )
  }, [
    activeStoreView.code,
    categoryTitleFallback,
    isPushedViewItemList,
    pageTitle,
    products,
  ])

  /**
   * Scroll to product grid when facetParam changes and facet menu is not used.
   * In other words: when facets in URL are change, e.g. when quick filter is used.
   */
  useEffect(() => {
    if (productGridRef?.current && facetParam && !isFacetMenuActive) {
      globalWindow?.scrollTo({
        top: productGridRef.current.offsetTop - 87,
        behavior: 'smooth',
      })
    }
  }, [facetParam, isFacetMenuActive])

  return (
    <>
      <Meta metaTitle={metaTitle} metaDescription={metaDescription} />

      <Section>
        {headerData.title && headerData.image && (
          <PageHeader
            title={headerData.title}
            subtitle={headerData.subtitle}
            image={headerData.image}
          />
        )}

        {quickFilters?.length !== 0 && (
          <QuickFilters filters={quickFilters.filter(stripMaybes)} />
        )}

        <StyledContainer isLarge>
          <Grid ref={productGridRef}>
            <AsideDesktop>
              <TopText>
                <Trans>Filter by</Trans>
              </TopText>

              <CategoryFiltersForm
                facets={facets}
                landingPageUrls={landingPageUrlsData?.filter(stripMaybes) ?? []}
                filterExplanations={filterExplanations}
                setIsFacetMenuActive={setIsFacetMenuActive}
              />
            </AsideDesktop>

            <div>
              <Header>
                <H2>
                  {pageTitle ?? categoryTitleFallback} &#40;{resultCount}&#41;
                </H2>
              </Header>

              {(craftDataCategoryLanding?.introduction ||
                craftDataCategory?.introduction) && (
                <IntroductionReadMoreWrapper showAll={showFullIntroduction}>
                  <Collapse isOpened>
                    <Introduction showAll={showFullIntroduction}>
                      <div>
                        <HtmlContent
                          html={
                            craftDataCategoryLanding?.introduction ??
                            craftDataCategory?.introduction ??
                            ''
                          }
                        />
                      </div>
                    </Introduction>
                  </Collapse>

                  {!showFullIntroduction && (
                    <IntroductionReadMoreButton
                      analyticsContext="category"
                      analyticsName="introduction-read-more"
                      onPress={() => setShowFullIntroduction(true)}
                    >
                      <Trans>Read more</Trans>
                    </IntroductionReadMoreButton>
                  )}

                  {showFullIntroduction && (
                    <IntroductionReadLessButton
                      analyticsContext="category"
                      analyticsName="introduction-read-less"
                      onPress={() => setShowFullIntroduction(false)}
                    >
                      <Trans>Read less</Trans>
                    </IntroductionReadLessButton>
                  )}
                </IntroductionReadMoreWrapper>
              )}

              <FilterButtonsMobileAndTablet>
                <StyledButtonSecondary
                  analyticsContext="category.layout"
                  analyticsName="open.filter.modal"
                  colorTheme="dark"
                  onClick={() => setShowCategoryFiltersModal(true)}
                >
                  <StyledFilterIcon />

                  <Trans>Filter</Trans>
                </StyledButtonSecondary>

                {sortOptions.length !== 0 && (
                  <CategorySortForm
                    sortOptions={
                      tweakwiseNavigationResults.properties.sortFields
                    }
                    showLabel={false}
                  />
                )}
              </FilterButtonsMobileAndTablet>

              <SortByWrapperDesktop>
                <CategoryActiveFilters
                  facets={facets}
                  isLoadingFacets={isLoadingTweakwiseNavigation}
                  landingPageUrls={
                    landingPageUrlsData?.filter(stripMaybes) ?? []
                  }
                  isLoadingLandingPageUrls={isLoadingLandingPageUrls}
                  setIsFacetMenuActive={setIsFacetMenuActive}
                />

                {sortOptions.length !== 0 && (
                  <StyledCategorySortForm
                    sortOptions={
                      tweakwiseNavigationResults.properties.sortFields
                    }
                  />
                )}
              </SortByWrapperDesktop>

              <CategoryProductList
                websiteData={websiteData}
                items={items}
                disableEcommerce={disableEcommerce}
              />
            </div>
          </Grid>

          <StyledCategoryPagination
            currentPageIndex={tweakwiseNavigationResults.properties.currentPage}
            pageCount={tweakwiseNavigationResults.properties.nrOfPages}
          />

          <StyledPreviouslyViewedProducts disableEcommerce={disableEcommerce} />
        </StyledContainer>

        <Container>
          {(craftDataCategoryLanding?.bottomText ||
            craftDataCategory?.bottomText) && (
            <SeoTextWrapper>
              <HtmlContent
                html={
                  craftDataCategoryLanding?.bottomText ??
                  craftDataCategory?.bottomText ??
                  ''
                }
              />
            </SeoTextWrapper>
          )}

          {craftSeoIntroductionTitle && (
            <SeoIntroductionTitle>
              {craftSeoIntroductionTitle}
            </SeoIntroductionTitle>
          )}

          {craftSeoIntroductionText && (
            <SeoIntroductionTextWrapper>
              <HtmlContent html={craftSeoIntroductionText} />
            </SeoIntroductionTextWrapper>
          )}

          {craftSeoCollapsibleRichTexts?.length !== 0 &&
            craftSeoCollapsibleRichTexts?.map(
              (collapsibleText) =>
                collapsibleText?.richText && (
                  <StyledAccordion
                    key={`collapsible${collapsibleText?.uid}`}
                    label={
                      <CollapsibleItemLabel>
                        {collapsibleText?.title}
                      </CollapsibleItemLabel>
                    }
                    labelElement="h2"
                  >
                    <ItemContent>
                      <HtmlContent html={collapsibleText.richText} />
                    </ItemContent>
                  </StyledAccordion>
                ),
            )}
        </Container>
      </Section>

      <CategoryFiltersFormModalMobileAndTabletWrapper>
        <CategoryFiltersFormModal
          show={showCategoryFiltersModal}
          close={() => setShowCategoryFiltersModal(false)}
          facets={facets}
          resultCount={resultCount}
          router={router}
          landingPageUrls={landingPageUrlsData?.filter(stripMaybes) ?? []}
          filterExplanations={filterExplanations}
          setIsFacetMenuActive={setIsFacetMenuActive}
        />
      </CategoryFiltersFormModalMobileAndTabletWrapper>
    </>
  )
}

export default CategoryLayout
